import Div from "@jumbo/shared/Div";
import {
  Button,
  Checkbox,
  Modal,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { fetchVeneerInventory } from "app/redux/actions/Inventory/VeneerInventory/veneerDispatch";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import { veneerInventoryFilter } from "app/utils/constants/sessionFilter";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VeneerTableRow from "../Components/veneerTableRow";
import ItemDetailsModal from "./ItemDetailModal";

const tableHeadCellSx = {
  textAlign: "left",
  minWidth: "100px",
  verticalAlign: "middle",
  px: 1,
  color:"white"
};

const tableHeadSortSx = {
  color: "white",
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important", // Set the color for the sorting icon
  },
  "&:hover": {
    color: "white !important", // Set the color when hovering
  },
};

const tableRowHeadSticky = {
  textAlign: "center",
  minWidth: "0px",
  width: "50px",
  verticalAlign: "middle",
  color: "white",
  px: 1,
  position: "sticky",
  right: 0,
  height: "58px",
  zIndex: 1,
  bgcolor: "#7352C7",
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const VeneerTableListing = ({ allFilterState, setAllFilterState }) => {
  const veneerInventoryReducer = useSelector((state) => state?.veneerInventoryReducer);
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);
  const sort = allFilterState?.sort;
  const sortBy = allFilterState?.sortBy;
  const page = allFilterState?.page;

  const [itemDetailsForModal, setItemDetailsForModal] = useState(null);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const handleOpenDetailModal = (rowDetails) => {
    setOpenDetailModal(true);
  };

  const handleSort = function (name) {
    const filter = {
      ...allFilterState,
      sort: allFilterState.sort === "desc" ? "asc" : "desc",
      sortBy: name,
      page: 1,
    };
    setAllFilterState(filter);
    dispatch(fetchVeneerInventory(filter));
    sessionStorage.setItem(veneerInventoryFilter, JSON.stringify(filter));
  };

  const handleCancelIssues = function () {
    setSelectedIds([]);
  };

  const handleChangePage = (event, newPage) => {
    const filter = {
      ...allFilterState,
      page: newPage,
    };
    setAllFilterState(filter);
    dispatch(fetchVeneerInventory(filter));
    sessionStorage.setItem(veneerInventoryFilter, JSON.stringify(filter));
  };

  const isSelectedAll = () => {
    const allSelected = veneerInventoryReducer?.data?.every((elm) => {
      return selectedIds.includes(elm?._id);
    });
    return allSelected;
  };

  const handleSelectAll = function (event) {
    try {
      dispatch(setLoading());
      const checked = event.target.checked;
      if (checked) {
        setSelectedIds((prev) => {
          const selecedIdsData = new Set([...veneerInventoryReducer?.data.map((elm) => elm._id), ...prev]);
          return [...selecedIdsData];
        });
      } else {
        const uncheckAll = selectedIds.filter((id) => {
          return !veneerInventoryReducer?.data.some((elm) => elm?._id === id);
        });
        setSelectedIds(uncheckAll);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      {selectedIds?.length > 0 && (
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            bgcolor: "#dbdbdb",
            px: 2,
            py: 1,
            borderRadius: "5px",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <Typography variant="h5" sx={{ color: "black", mt: 1 }}>
            {selectedIds?.length} Bundle Selected
          </Typography>
          <Div sx={{ display: "flex", columnGap: 2, rowGap: 2, flexWrap: "wrap" }}>
            <Button variant="outlined" onClick={handleCancelIssues}>
              Cancel
            </Button>
          </Div>
        </Div>
      )}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {
                  // py: 2,
                },
              }}
            >
              <TableCell
                sx={{
                  ...tableHeadCellSx,
                  color: "white",
                  minWidth: "50px",
                  textAlign: "center",
                }}
              >
                <Checkbox
                  {...label}
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                  checked={isSelectedAll()}
                  onChange={handleSelectAll}
                  size="small"
                />
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "veneer_invoice_details.inward_sr_no"}
                  direction={sort}
                  onClick={(e) => handleSort("veneer_invoice_details.inward_sr_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Sr No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "veneer_invoice_details.inward_date"}
                  direction={sort}
                  onClick={(e) => handleSort("veneer_invoice_details.inward_date")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "110px" }}>
                <TableSortLabel
                  active={sortBy === "veneer_invoice_details.invoice_Details.invoice_no"}
                  direction={sort}
                  onClick={(e) => handleSort("veneer_invoice_details.invoice_Details.invoice_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "item_sr_no"}
                  direction={sort}
                  onClick={(e) => handleSort("item_sr_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Veneer Sr No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "180px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_details.company_details.supplier_name"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_details.company_details.supplier_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "180px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_item_name"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_item_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_code"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_code")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Code
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "item_name"}
                  direction={sort}
                  onClick={(e) => handleSort("item_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "item_sub_category_name"}
                  direction={sort}
                  onClick={(e) => handleSort("item_sub_category_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Sub Category
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "log_code"}
                  direction={sort}
                  onClick={(e) => handleSort("log_code")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log Code
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "bundle_number"}
                  direction={sort}
                  onClick={(e) => handleSort("bundle_number")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Bundle Number
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "pallet_number"}
                  direction={sort}
                  onClick={(e) => handleSort("pallet_number")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Pallet Number
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "length"}
                  direction={sort}
                  onClick={(e) => handleSort("length")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "width"}
                  direction={sort}
                  onClick={(e) => handleSort("width")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Width
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "thickness"}
                  direction={sort}
                  onClick={(e) => handleSort("thickness")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Thickness
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "number_of_leaves"}
                  direction={sort}
                  onClick={(e) => handleSort("number_of_leaves")}
                  sx={{ ...tableHeadSortSx }}
                >
                  No Of Leaves
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "total_sq_meter"}
                  direction={sort}
                  onClick={(e) => handleSort("total_sq_meter")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Total Sq Meter
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "cut_name"}
                  direction={sort}
                  onClick={(e) => handleSort("cut_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Cut Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "series_name"}
                  direction={sort}
                  onClick={(e) => handleSort("series_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Series Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "grades_name"}
                  direction={sort}
                  onClick={(e) => handleSort("grades_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Grade Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "veneer_invoice_details.currency"}
                  direction={sort}
                  onClick={(e) => handleSort("veneer_invoice_details.currency")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Currency
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "exchange_rate"}
                  direction={sort}
                  onClick={(e) => handleSort("exchange_rate")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Exchange Rate
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "rate_in_currency"}
                  direction={sort}
                  onClick={(e) => handleSort("rate_in_currency")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Rate In Currency
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "rate_in_inr"}
                  direction={sort}
                  onClick={(e) => handleSort("rate_in_inr")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Rate In Inr
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "amount"}
                  direction={sort}
                  onClick={(e) => handleSort("amount")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "155px" }}>
                <TableSortLabel
                  active={sortBy === "expense_amount"}
                  direction={sort}
                  onClick={(e) => handleSort("expense_amount")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Expense Amount
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "remark"}
                  direction={sort}
                  onClick={(e) => handleSort("remark")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Remark
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>Approval Status</TableCell>
              <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky, right: "50px" }}>Details</TableCell>
              <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {veneerInventoryReducer?.data && veneerInventoryReducer?.data?.length > 0 ? (
              veneerInventoryReducer?.data?.map((e, i) => {
                return (
                  <VeneerTableRow
                    key={e?._id}
                    e={e}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    setItemDetailsForModal={setItemDetailsForModal}
                    handleOpenDetailModal={handleOpenDetailModal}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={14} sx={{ textAlign: "center", py: 2, fontSize: 18 }}>
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          size="medium"
          count={veneerInventoryReducer?.totalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
        <Modal
          open={openDetailModal}
          onClose={() => {
            setItemDetailsForModal(null);
            setOpenDetailModal(false);
          }}
        >
          <ItemDetailsModal itemDetailsForModal={itemDetailsForModal} setOpenDetailModal={setOpenDetailModal}/>
        </Modal>
      </TableContainer>
    </>
  );
};

export default React.memo(VeneerTableListing);
