import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Modal,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { fetchLogInventory } from "app/redux/actions/Inventory/LogInventory/logDispatch";
import { hideLoading, setLoading } from "app/redux/actions/Loading";
import { logInventoryFilter } from "app/utils/constants/sessionFilter";
import { Axios } from "index";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import LogTableRow from "../Components/LogTableRow";
import ItemDetailsModal from "./ItemDetailModal";

const tableHeadCellSx = {
  textAlign: "left",
  minWidth: "100px",
  verticalAlign: "middle",
  px: 1,
  color: "white"
};

const tableHeadSortSx = {
  color: "white",
  "&.MuiTableSortLabel-root.Mui-active": {
    color: "white !important",
  },
  "& .MuiTableSortLabel-icon": {
    color: "white !important", // Set the color for the sorting icon
  },
  "&:hover": {
    color: "white !important", // Set the color when hovering
  },
};

const tableRowHeadSticky = {
  textAlign: "center",
  minWidth: "0px",
  width: "50px",
  verticalAlign: "middle",
  color: "white",
  px: 1,
  position: "sticky",
  right: 0,
  height: "58px",
  zIndex: 1,
  bgcolor: "#7352C7",
};

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const LogTableListing = ({ allFilterState, setAllFilterState }) => {
  const logInventoryReducer = useSelector((state) => state?.logInventoryReducer);
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);
  const sort = allFilterState?.sort;
  const sortBy = allFilterState?.sortBy;
  const page = allFilterState?.page;

  const [itemDetailsForModal, setItemDetailsForModal] = useState(null);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const handleOpenDetailModal = (rowDetails) => {
    setOpenDetailModal(true);
  };

  const handleSort = function (name) {
    const filter = {
      ...allFilterState,
      sort: allFilterState.sort === "desc" ? "asc" : "desc",
      sortBy: name,
      page: 1,
    };
    setAllFilterState(filter);
    dispatch(fetchLogInventory(filter));
    sessionStorage.setItem(logInventoryFilter, JSON.stringify(filter));
  };

  const handleCancelIssues = function () {
    setSelectedIds([]);
  };

  const handleChangePage = (event, newPage) => {
    const filter = {
      ...allFilterState,
      page: newPage,
    };
    setAllFilterState(filter);
    dispatch(fetchLogInventory(filter));
    sessionStorage.setItem(logInventoryFilter, JSON.stringify(filter));
  };

  const isSelectedAll = () => {
    const allSelected = logInventoryReducer?.data?.every((elm) => {
      return selectedIds.includes(elm?._id);
    });
    return allSelected;
  };

  const handleSelectAll = function (event) {
    try {
      dispatch(setLoading());
      const checked = event.target.checked;
      if (checked) {
        setSelectedIds((prev) => {
          const selecedIdsData = new Set([...logInventoryReducer?.data.map((elm) => elm._id), ...prev]);
          return [...selecedIdsData];
        });
      } else {
        const uncheckAll = selectedIds.filter((id) => {
          return !logInventoryReducer?.data.some((elm) => elm?._id === id);
        });
        setSelectedIds(uncheckAll);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleIsssueForCrosscutting = async function () {
    try {
      dispatch(setLoading());
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const issueForCrosscutting = await Axios.post(
        "/log-inventory/issue_for_crosscutting",
        {
          log_items_ids: selectedIds,
        },
        config
      );

      if (issueForCrosscutting.status === 200) {
        Swal.fire({
          title: "Issued for crosscutting",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(fetchLogInventory(allFilterState));
        setSelectedIds([]);
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleIsssueForFlitching = async function () {
    try {
      dispatch(setLoading());
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const issueForFlitching = await Axios.post(
        "/log-inventory/issue_for_flitching",
        {
          log_items_ids: selectedIds,
        },
        config
      );

      if (issueForFlitching.status === 200) {
        Swal.fire({
          title: "Issued for flitching",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch(fetchLogInventory(allFilterState));
        setSelectedIds([]);
      }
    } catch (error) {
      Swal.fire({
        title: error?.response?.data?.message,
        icon: "error",
        showConfirmButton: true,
      });
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      {selectedIds?.length > 0 && (
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
            bgcolor: "#dbdbdb",
            px: 2,
            py: 1,
            borderRadius: "5px",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <Typography variant="h5" sx={{ color: "black", mt: 1 }}>
            {selectedIds?.length} Bundle Selected
          </Typography>
          <Div sx={{ display: "flex", columnGap: 2, rowGap: 2, flexWrap: "wrap" }}>
            <Button variant="outlined" onClick={()=>{
              Swal.fire({
                text: "Are you sure you want to cancel?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleCancelIssues()
                }
              });
            }}>
              Cancel
            </Button>
            <LoadingButton sx={{ textTransform: "capitalize" }} size="medium" variant="contained" onClick={()=>{
               Swal.fire({
                text: "Are you sure you want to Issue for Crosscutting?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleIsssueForCrosscutting()
                }
              });
              }}>
              Issue For Crosscutting
            </LoadingButton>
            <LoadingButton sx={{ textTransform: "capitalize" }} size="medium" variant="contained" onClick={()=>{
              Swal.fire({
                text: "Are you sure you want to Issue for Flitching?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  handleIsssueForFlitching()
                }
              });
            }}>
              Issue For Flitching
            </LoadingButton>
          </Div>
        </Div>
      )}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: "#7352C7",
                color: "white",
                "& .MuiTableCell-root": {
                  // py: 2,
                },
              }}
            >
              <TableCell
                sx={{
                  ...tableHeadCellSx,
                  color: "white",
                  minWidth: "50px",
                  textAlign: "center",
                }}
              >
                {/* <FormControlLabel
                                sx={{
                                    "& .MuiCheckbox-root.Mui-checked": {
                                        color: "white",
                                        borderColor: "white"
                                    }
                                }}
                                control={<Checkbox  size='small'/>}
                                // label="Select All"
                            /> */}
                <Checkbox
                  {...label}
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                  checked={isSelectedAll()}
                  onChange={handleSelectAll}
                  size="small"
                />
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "log_invoice_details.inward_sr_no"}
                  direction={sort}
                  onClick={(e) => handleSort("log_invoice_details.inward_sr_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Sr No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "log_invoice_details.inward_date"}
                  direction={sort}
                  onClick={(e) => handleSort("log_invoice_details.inward_date")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Inward Date
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "log_invoice_details.invoice_Details.invoice_no"}
                  direction={sort}
                  onClick={(e) => handleSort("log_invoice_details.invoice_Details.invoice_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "110px" }}>
                <TableSortLabel
                  active={sortBy === "item_sr_no"}
                  direction={sort}
                  onClick={(e) => handleSort("item_sr_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log Sr No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "180px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_details.company_details.supplier_name"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_details.company_details.supplier_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "180px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_item_name"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_item_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "supplier_log_no"}
                  direction={sort}
                  onClick={(e) => handleSort("supplier_log_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Supplier Log No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "170px" }}>
                <TableSortLabel
                  active={sortBy === "item_name"}
                  direction={sort}
                  onClick={(e) => handleSort("item_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "item_sub_category_name"}
                  direction={sort}
                  onClick={(e) => handleSort("item_sub_category_name")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Sub Category
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "125px" }}>
                <TableSortLabel
                  active={sortBy === "log_formula"}
                  direction={sort}
                  onClick={(e) => handleSort("log_formula")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log Formula
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "log_no"}
                  direction={sort}
                  onClick={(e) => handleSort("log_no")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Log No
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "invoice_length"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_length")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "invoice_diameter"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_diameter")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice Diameter
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "invoice_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("invoice_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Invoice Cmt
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "indian_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("indian_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Indian Cmt
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>
                <TableSortLabel
                  active={sortBy === "physical_length"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_length")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Length
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "physical_diameter"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_diameter")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Diameter
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "130px" }}>
                <TableSortLabel
                  active={sortBy === "physical_cmt"}
                  direction={sort}
                  onClick={(e) => handleSort("physical_cmt")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Physical Cmt
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "log_invoice_details.currency"}
                  direction={sort}
                  onClick={(e) => handleSort("log_invoice_details.currency")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Currency
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "140px" }}>
                <TableSortLabel
                  active={sortBy === "exchange_rate"}
                  direction={sort}
                  onClick={(e) => handleSort("exchange_rate")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Exchange Rate
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "160px" }}>
                <TableSortLabel
                  active={sortBy === "rate_in_currency"}
                  direction={sort}
                  onClick={(e) => handleSort("rate_in_currency")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Rate In Currency
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "120px" }}>
                <TableSortLabel
                  active={sortBy === "rate_in_inr"}
                  direction={sort}
                  onClick={(e) => handleSort("rate_in_inr")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Rate In Inr
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "amount"}
                  direction={sort}
                  onClick={(e) => handleSort("amount")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "155px" }}>
                <TableSortLabel
                  active={sortBy === "expense_amount"}
                  direction={sort}
                  onClick={(e) => handleSort("expense_amount")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Expense Amount
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx }}>
                <TableSortLabel
                  active={sortBy === "remark"}
                  direction={sort}
                  onClick={(e) => handleSort("remark")}
                  sx={{ ...tableHeadSortSx }}
                >
                  Remark
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ ...tableHeadCellSx, minWidth: "150px" }}>Approval Status</TableCell>
              <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky, right: "50px" }}>Details</TableCell>
              <TableCell sx={{ ...tableHeadCellSx, ...tableRowHeadSticky }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logInventoryReducer?.data && logInventoryReducer?.data?.length > 0 ? (
              logInventoryReducer?.data?.map((e, i) => {
                return (
                  <LogTableRow
                    key={e?._id}
                    e={e}
                    allFilterState={allFilterState}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    setItemDetailsForModal={setItemDetailsForModal}
                    handleOpenDetailModal={handleOpenDetailModal}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={14} sx={{ textAlign: "center", py: 2, fontSize: 18 }}>
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          size="medium"
          count={logInventoryReducer?.totalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
            py: 1,
          }}
        />
        <Modal
          open={openDetailModal}
          onClose={() => {
            setItemDetailsForModal(null);
            setOpenDetailModal(false);
          }}
        >
          <ItemDetailsModal itemDetailsForModal={itemDetailsForModal} setOpenDetailModal={setOpenDetailModal} />
        </Modal>
      </TableContainer>
    </>
  );
};

export default React.memo(LogTableListing);
